import React from 'react';
import './introSection.sass';
import IntroName from './IntroName';
import IntroDescription from './IntroDescription';
import Status from '../../kit/flavor/Status';
import ScrollButton from '../../kit/input/ScrollButton';
import {ElementId} from '../../../Enums'; // -7165 -3FD7 -75AF -94E5

interface IntroSectionProps
{

}

const IntroSection: React.FunctionComponent<IntroSectionProps> = (props) =>
{
	return (
		<div className={'root-7165'}>
			<div className={'section-7165'}>
				<IntroName />
				<IntroDescription />
				<div>
					<ScrollButton
						id={ElementId.SKILLS}
						loadingText={'Leveling up...'}
					>
						Skills
					</ScrollButton>
					<ScrollButton id={ElementId.SKILLS}>Work</ScrollButton>
					<ScrollButton id={ElementId.SKILLS}>Contact</ScrollButton>
				</div>
			</div>
			<div className={'status-7165'}>
				<Status />
			</div>
		</div>

	);
};

export default IntroSection;