import React from 'react';
import './introName.sass'; // -DBBB -8181 -1C46 -3E1A

interface IntroNameProps
{

}

const IntroName: React.FunctionComponent<IntroNameProps> = (props) =>
{
	return (
		<div className={'root-DBBB'}>
			<div className={`row-DBBB`}>
				<div className={'large-DBBB'}>
					HI
				</div>
				<div className={'small-DBBB'}>
					<div>
						I'M
					</div>
					<div>
						KAI
					</div>
				</div>
			</div>
			<div className={"underline-DBBB"}>
				DEV//DEV//DEV//DEV//DEV//DEV//DEV//DEV//DEV//DEV//DEV//
			</div>
		</div>
	);
};

export default IntroName;