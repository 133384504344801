import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import './curtainsContextProvider.sass';
import Loading from '../animations/Loading'; // -5150 -E19B -159B -D0C1

export interface Conceal
{
	ms: number,
	loadingColors: string[],
	loadingText: string,
	force: boolean,
}

interface Data
{
	in: boolean,
	loadingColors: string[],
	loadingText: string
}

export const CurtainsContext = React.createContext<null | {
	conceal: ((opt: Partial<Conceal>) => boolean),
	enterDuration: number,
	exitDuration: number,
}>(null);

const CurtainsContextProvider: React.FunctionComponent<{ children: ReactNode }> = (props) =>
{

	const [data, setData] = useState<Data>({
		in: true,
		loadingText: 'Loading...',
		loadingColors: ['#6A15A3', '#F62458', '#F62458'],
	});

	const conceal = useCallback((props: Partial<Conceal>): boolean =>
	{
		let success = false;
		const tProps = {
			loadingText: props.loadingText || 'Loading...',
			loadingColors: props.loadingColors || ['#6A15A3', '#F62458', '#F62458'],
			ms: 1000,
			force: false,
			...props,
		};
		setData((data) =>
		{
			if (data.in && !tProps.force)
			{
				return data;
			}

			setTimeout(() =>
			{
				setData((data) =>
				{
					return {
						...data,
						in: false,
					};
				});
			}, 500 + tProps.ms);

			success = true;

			return {
				in: true,
				loadingText: tProps.loadingText,
				loadingColors: tProps.loadingColors,
			};
		});
		return success;
	}, []);

	useEffect(() =>
	{
		const handler = () =>
		{
			conceal({ms: 2000, force: true});
		};
		window.addEventListener('load', handler);

		return () =>
		{
			window.removeEventListener('load', handler);
		};
	});

	return (
		<CurtainsContext.Provider value={{conceal, enterDuration: 500, exitDuration: 500}}>
			<div>
				<CSSTransition
					in={data.in}
					timeout={500}
					classNames={'curtain-5150'}
					unmountOnExit={true}
					mountOnEnter={true}
					appear={true}
				>
					<div className={'container-5150'}>
						<div className={'loading-container-5150'}>
							<div className={'loading-animation-5150'}>
								<Loading
									colors={data.loadingColors}
									text={data.loadingText}
								/>
							</div>
						</div>
						<div className={'left-curtain-5150'} />
						<div className={'right-curtain-5150'} />
					</div>
				</CSSTransition>
				<div className={'content-5150'}>
					{props.children}
				</div>
			</div>
		</CurtainsContext.Provider>
	);
};

export default observer(CurtainsContextProvider);