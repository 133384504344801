import React, {useEffect, useState} from 'react';
import {detect} from 'detect-browser';

interface ResolutionProps
{

}

const Resolution: React.FunctionComponent<ResolutionProps> = (props) =>
{

	const [data, setData] = useState<{ width: number, height: number }>({
		width: document.body.clientWidth,
		height: document.body.clientHeight,
	});

	useEffect(() =>
	{
		const handler = () =>
		{
			setData({
				width: document.body.clientWidth,
				height: document.body.clientHeight,
			});
		};

		window.addEventListener('resize', handler);

		return () =>
		{
			window.removeEventListener('resize', handler);
		};
	}, []);

	return (
		<span>{data.width}x{data.height}</span>
	);
};

export default Resolution;