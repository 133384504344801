import React from 'react';
import Resolution from './Resolution';
import {detect} from 'detect-browser';
import "./status.sass" // -9459 -AABC -46BD -69B8

interface StatusProps
{

}

const Status: React.FunctionComponent<StatusProps> = (props) =>
{
	const browser = {
		name: "UNKNOWN USER",
		version: "UNKNOWN VERSION",
		os: "UNKNOWN OS",
		...detect()
	}

	return (
		<div className={"root-9459"}>
			<div>
				<div>STATUS</div>
				<div>RESOLUTION</div>
				<div>USER</div>
				<div>VERSION</div>
				<div>USER STATUS</div>
				<div>真棒</div>
			</div>
			<div className={"text-9459"}>
				<div>RUNNING</div>
				<div><Resolution/></div>
				<div>{browser.name}</div>
				<div>{browser.version}</div>
				<div>OK</div>
				<div>是</div>
			</div>
		</div>
	);
};

export default Status;