import React from 'react';
import IntroSection from './components/sections/intro/IntroSection';
import Resolution from './components/kit/flavor/Resolution';
import CurtainsContext from './components/kit/transitions/CurtainsContextProvider';
import ChevronSpacer from './components/kit/flavor/ChevronSpacer';
import './app.sass';
import SkillSection from './components/sections/skill/SkillSection';
import InitialScroll from './components/misc/InitialScroll'; // -56EE -9E34 -BDD9 -CEEF

function App()
{
	return (
		<div className='App'>
			<InitialScroll />
			<CurtainsContext>
				<IntroSection />
				<div className={'spacer-271E'}>
					<ChevronSpacer text={'SKILLS'} />
				</div>
				<SkillSection />
				<div className={'spacer-271E'}>
					<ChevronSpacer text={'WORK'} />
				</div>
				<Resolution />
				<div className={'spacer-271E'}>
					<ChevronSpacer text={'CONTACT'} />
				</div>
			</CurtainsContext>
		</div>
	);
}

export default App;
