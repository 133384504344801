import React, {useEffect} from 'react';

interface InitialScrollProps
{

}

let done: boolean = false;

const InitialScroll: React.FunctionComponent<InitialScrollProps> = (props) =>
{
	useEffect(() =>
	{
		const handler = () =>
		{
			if(done){
				return;
			}
			const id = window.location.href.split("#")[1]
			document.getElementById(id)?.scrollIntoView(true)
			done = true;
		};
		window.addEventListener('load', handler);

		return () =>
		{
			window.removeEventListener('load', handler);
		};
	});

	return null;
};

export default InitialScroll;