import React from 'react';
import "./skillSectionTitle.sass" // -981F -D9F7 -AD05 -74E7

interface SkillSectionTitleProps
{

}

const SkillSectionTitle: React.FunctionComponent<SkillSectionTitleProps> = (props) =>
{
	return (
		<div className={"root-981F"}>
			<div className={"text-981F"}>
				MY
			</div>
			<div className={"rotated-981F"}>
				SKILLS
			</div>
		</div>
	);
};

export default SkillSectionTitle;