import React, {MouseEvent, MouseEventHandler, ReactNode} from 'react';
import './button.sass';
import Tilt from 'react-parallax-tilt';

interface StripedButtonProps
{
	children?: ReactNode;
	href?: string,
	onClick?: (e: MouseEvent) => void,
}

const Button: React.FunctionComponent<StripedButtonProps> = (props) =>
{

	return (
		<Tilt
			scale={1.1}
			tiltReverse={true}
		>
			<div className={'root-77E5'}>
				<a href={props.href} onClick={props.onClick}>
					<div className={'clip-77E5'}>
						<div className={'text-77E5'}>
							{props.children}
						</div>
					</div>
				</a>
			</div>
		</Tilt>
	);
};

export default Button;