import React, {memo} from 'react';
import './loading.sass';
import {useGradient} from '../../../hooks/useGradient';

interface LoadingProps
{
	text?: string;
	colors?: string[];
	duration?: number;
	offset?: number;
}

const Loading: React.FunctionComponent<LoadingProps> = (props) =>
{
	const tProps = {
		text: 'Loading...',
		colors: ['#6A15A3', '#F62458', '#F62458'],
		duration: 100,
		offset: 65,
		...props,
	};

	const bars = tProps.text.split('');
	const gradient = useGradient({colors: tProps.colors});

	return (
		<div className={'root-4E02'}>
			<div className={'text-4E02'}>
				{
					bars.map((letter) =>
					{
						return (
							<div key={letter}>
								{letter}
							</div>
						);
					})
				}
			</div>
			<div className={'bars-4E02'}>
				{
					bars.map((letter, index) =>
					{
						return (
							<div
								key={letter}
								style={{
									animation: `bar-4E02 ${bars.length * tProps.duration}ms ${index * tProps.offset}ms infinite ease-in-out`,
									backgroundColor: gradient.getColor((index + 1) / bars.length),
								}}
							/>
						);
					})
				}
			</div>
		</div>
	);
};

export default memo(Loading);