import React, {memo, useEffect, useState} from 'react';
import Chevron from '../shapes/Chevron';
import './chevronSpacer.sass';
import {useGradient} from '../../../hooks/useGradient';

interface ChevronLineProps
{
	text?: string;
	amount?: number;
	colors?: string[],
}

const ChevronSpacer: React.FunctionComponent<ChevronLineProps> = (props) =>
{
	const [width, setWidth] = useState(document.body.clientWidth);
	const calculatedAmount = Math.floor((width - 250) / 2 / 50) * 2;
	useEffect(() =>
	{
		const handler = () =>
		{
			setWidth(document.body.clientWidth);
		};

		window.addEventListener('resize', handler);

		return () =>
		{
			window.removeEventListener('resize', handler);
		};
	}, []);

	const tProps = {
		text: 'SKILLS',
		colors: ['#441660', '#AF159E', '#FB2361'],
		...props,
		amount: props.amount ? props.amount * 2 : calculatedAmount,
	};

	const gradient = useGradient({colors: tProps.colors});
	const items = [];

	for (let i = 0; i < tProps.amount; i++)
	{
		const backgroundColor = gradient.getColor((i + 1) / tProps.amount);
		const style = {
			width: 50,
			height: 50,
			backgroundColor,
		};

		if (i === Math.floor(tProps.amount * 0.5))
		{
			items.push(
				<div
					key={`large-${i}`}
					className={'container-632A'}
					style={{animation: `chevron-632A 2000ms ${i * 100}ms infinite ease-in-out`}}
				>
					<Chevron
						style={style}
						seamless={true}
					/>

					<div
						style={{backgroundColor}}
						className={'text-632A'}
					>
						{tProps.text}
					</div>

					<Chevron
						style={{
							...style,
							marginLeft: -13,
						}}
					/>
				</div>,
			);
		}

		items.push(
			<Chevron
				key={i}
				style={{
					...style,
					backgroundColor,
					animation: `chevron-632A 2000ms ${i * 100}ms infinite ease-in-out`,
				}}
			/>,
		);
	}

	return (
		<div className={'root-632A'}>
			{items}
		</div>
	);
};

export default memo(ChevronSpacer);