import React from 'react';
import './skillSection.sass';
import SkillSectionTitle from './SkillSectionTitle';
import {ElementId} from '../../../Enums'; // -9C07 -C6CE -8A85 -83B0

interface SkillSectionProps
{

}

const SkillSection: React.FunctionComponent<SkillSectionProps> = (props) =>
{
	return (
		<div
			className={'root-9C07'}
			id={ElementId.SKILLS}
		>
			<SkillSectionTitle />
			<div>
				Content
			</div>
		</div>
	);
};

export default SkillSection;