import React from 'react';
import "./introDescription.sass" // -B3C9 -F27B -1E76 -5B67

interface IntroDescriptionProps
{

}

const IntroDescription: React.FunctionComponent<IntroDescriptionProps> = (props) =>
{
	return (
		<div className={"root-B3C9"}>
			But, like most politicians, he promised more than he could deliver.
			Tell them I hate them. Whoa a real live robot; or is that some kind of cheesy New Year's costume? So I really am important? How I feel when I'm drunk is correct? Large bet on myself in round one. Well, then good news! It's a suppository.

			Well I'da done better, but it's plum hard pleading a case while awaiting trial for that there incompetence.
			Shinier than yours, meatbag.
			It's okay, Bender. I like cooking too.
		</div>
	);
};

export default IntroDescription;