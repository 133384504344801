import React, {CSSProperties, memo} from 'react';

interface ChevronProps
{
	style?: CSSProperties;
	className?: string;
	seamless?: boolean;
}

const Chevron: React.FunctionComponent<ChevronProps> = (props) =>
{
	if (!props.seamless)
	{
		return (
			<div>
				<div
					style={{
						clipPath: `polygon(75% 0, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)`,
						backgroundColor: '#AF159E',
						width: 100,
						height: 100,
						...props.style,
					}}
					className={props.className}
				/>
			</div>
		);
	}

	const width = (() =>
	{
		const width = props.style?.width || 100;
		if (Number.isSafeInteger(width))
		{
			return width + 'px';
		}
		return width;
	})();

	return (
		<div style={{marginRight: `calc(${width} * -0.27)`}}>
			<div
				style={{
					clipPath: `polygon(75% 0, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)`,
					backgroundColor: '#AF159E',
					width: 100,
					height: 100,
					...props.style,
				}}
				className={props.className}
			/>
		</div>
	);

};

export default memo(Chevron);