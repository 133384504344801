import React, {MouseEvent, MouseEventHandler, ReactNode} from 'react';
import Button from './Button';
import {ElementId} from '../../../Enums';
import {CurtainsContext} from '../transitions/CurtainsContextProvider';

interface ScrollButtonProps
{
	children?: ReactNode;
	loadingText?: string;
	id: ElementId,
}

const ScrollButton: React.FunctionComponent<ScrollButtonProps> = (props) =>
{
	const curtains = React.useContext(CurtainsContext);

	const onClick = (e: MouseEvent) =>
	{
		const elem = document.getElementById(props.id);
		if (elem && curtains)
		{
			e.preventDefault();

			curtains.conceal({...props, ms: 2000});
			setTimeout(() =>
			{
				elem.scrollIntoView(true);
			}, curtains.enterDuration);
		}
	};

	return (
		<Button
			href={`#${props.id}`}
			onClick={onClick}
		>
			{props.children}
		</Button>
	);
};

export default ScrollButton;