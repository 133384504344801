import {useMemo} from 'react';

export interface UseGradient
{
	colors: string[],
}

export const useGradient = (props: UseGradient) =>
{

	/**
	 * Split the hex codes into rgb numbers for easier use
	 */
	const splitted = useMemo(() =>
	{
		return props.colors.map((color) =>
		{
			return [
				parseInt(color.substring(1, 3), 16),
				parseInt(color.substring(3, 5), 16),
				parseInt(color.substring(5, 7), 16),
			];
		});
	}, [props.colors]);

	/**
	 * Generate a gradient color between the two closest candidates
	 * @param progress
	 */
	const getColor = (progress: number): string =>
	{
		const baseColorIndex = Math.floor(progress / (1 / (props.colors.length - 1)));
		const secondColorIndex = props.colors[baseColorIndex + 1] ? baseColorIndex + 1 : baseColorIndex;
		const colorRatio = progress / (1 / (props.colors.length - 1)) % 1;

		const r = Math.ceil(splitted[baseColorIndex][0] * (1 - colorRatio) + splitted[secondColorIndex][0] * colorRatio).toString(16).padStart(2, '0');
		const g = Math.ceil(splitted[baseColorIndex][1] * (1 - colorRatio) + splitted[secondColorIndex][1] * colorRatio).toString(16).padStart(2, '0');
		const b = Math.ceil(splitted[baseColorIndex][2] * (1 - colorRatio) + splitted[secondColorIndex][2] * colorRatio).toString(16).padStart(2, '0');
		return `#${r}${g}${b}`;
	};

	return {
		getColor,
	};
};